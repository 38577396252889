import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { CssBaseline, Box, Slide, Alert, Snackbar } from '@mui/material';
import GlobalTip from '../providers/GlobalTip';
import TopAppBar from '../components/layout/TopAppBar';
import LeftNav from '../components/layout/LeftNav';
import Request from "../services/Request";

const drawerWidth = 80;

function MessageTransitionDown(props) {
	return <Slide {...props} direction="down" />;
}

function Layout(props) {
	let globalTipContext = GlobalTip.useMessage();
	const [navOpen, setNavOpen] = React.useState(false);
	const [showAlert, setShowAlert] = React.useState(false);
	const [banners, setBanners] = React.useState([]);
// Add this calculation at the top of your component
	const bannerHeight = banners.length * 60;

	const cssInJs = {
		root: {
			minHeight: '100vh',
			display: 'flex',
			flexDirection: 'column',
			// Target the specific MUI components with nested selectors
			'& header.MuiPaper-root': {
				marginTop: showAlert ? bannerHeight + 'px' : 0,
			},
			'& .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft': {
				marginTop: showAlert ? bannerHeight + 'px' : 0,
			}
		},
		bannerContainer: {
			position: 'fixed',
			width: '100%',
			top: 0,
			zIndex: 1301, // Higher z-index to ensure visibility
			display: 'flex',
			flexDirection: 'column'
		},
		appContainer: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
		},
		appBar: {
			position: 'fixed',
			top: showAlert ? (banners.length * 60) + 'px' : 0
		},
		leftNav: {
			'& .MuiPaper-root': {
				marginTop: showAlert ? (banners.length * 60 + 50) + 'px' : '50px'
			}
		},
		contentSection: {
			flexGrow: 1,
			px: 2,
			mt: showAlert ? (banners.length * 60 + 50) + 'px' : '50px',
			ml: 2,
			mr: 2
		},
		alert: {
			width: '100%',
			minHeight: '60px',
			'& a': {
				textDecoration: 'none',
				borderBottom: '1px solid',
				color: 'inherit'
			},
			border: '1px dotted',
		}
	};

	const handleDrawerToggle = () => {
		setNavOpen(!navOpen);
	};

	React.useEffect(() => {
		Request.get('/api/banner-status', {credentials: 'include'}, true).then(response => {
			console.log('Full response:', response);

			// The data is directly in response.data, not in response.data.data
			if (response.data && response.data.shouldShowAlert) {
				console.log('Setting banners:', response.data.banners);
				setBanners(response.data.banners || []);
				setShowAlert(true);
			} else {
				console.warn('No banners or shouldShowAlert flag found');
			}
		})
			.catch(error => console.error('Error fetching banners:', error));
	}, []);

	// Log whenever banners or showAlert changes
	useEffect(() => {
		console.log('Rendering state:', { showAlert, bannerCount: banners.length });
	}, [showAlert, banners]);

	return (
	<Box sx={cssInJs.root}>
		{showAlert && banners.length > 0 && (
			<Box sx={cssInJs.bannerContainer}>
				{banners.map((banner) => (
					<Alert
						key={banner.id}
						severity="warning"
						sx={cssInJs.alert}
					>
						<div dangerouslySetInnerHTML={{ __html: banner.content }} />
					</Alert>
				))}
			</Box>
		)}

		<CssBaseline />

			<Box sx={cssInJs.appContainer}>
				<TopAppBar id={'app-bar-global'}
						   width={drawerWidth}
						   sx={cssInJs.appBar}
				/>
				<Box sx={{ display: 'flex', flexGrow: 1 }}>
					<LeftNav id={'left-nav-global'}
							 width={drawerWidth}
							 navOpen={navOpen}
							 onNavClose={handleDrawerToggle}
							 sx={cssInJs.leftNav}
					/>
					<Box component="main" sx={cssInJs.contentSection}>
						<Outlet />
					</Box>
				</Box>
			</Box>

			<Snackbar open={globalTipContext.messageOpen} TransitionComponent={MessageTransitionDown} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert variant="filled" severity="warning" sx={{ width: '100%' }}>
					{globalTipContext.messageContent}
				</Alert>
			</Snackbar>
			<Snackbar open={globalTipContext.successOpen} TransitionComponent={MessageTransitionDown} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert variant="filled" severity="success" sx={{ width: '100%' }}>
					{globalTipContext.successContent}
				</Alert>
			</Snackbar>
		</Box>
	);
}

export default Layout;