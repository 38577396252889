import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Widget } from '@typeform/embed-react';
import { useSurvey } from '../SurveyContext';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Dialog, DialogContent, DialogTitle } from '@mui/material';

const SurveyModal = () => {
    const { surveyCompleted, surveyClosed, handleSurveySubmit, handleClose, handleShowSurvey } = useSurvey();
    const [showModal, setShowModal] = useState(false);
    const [userData, setUserData] = useState({ name: '', email: '' });

    // Initialize localStorage on component mount if needed
    useEffect(() => {
        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        if (currentUser && currentUser.id) {
            const name = `${currentUser.first_name} ${currentUser.last_name}`;
            const email = currentUser.email;
            setUserData({ name, email });

            if (localStorage.getItem(`surveyCompleted_${currentUser.id}`) === null) {
                localStorage.setItem(`surveyCompleted_${currentUser.id}`, 'false');
            }

            if (localStorage.getItem(`surveyClosed_${currentUser.id}`) === null) {
                localStorage.setItem(`surveyClosed_${currentUser.id}`, 'false');
            }
        }
    }, []);

    // Update showModal based on context state changes
    useEffect(() => {
        if (!surveyCompleted && !surveyClosed) {
            setShowModal(false); // completely disable modal
            // setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [surveyCompleted, surveyClosed]);

    const handleSurveySubmitWrapper = () => {
        handleSurveySubmit();
        // No need to setShowModal(false) here as it will be updated by the useEffect
    };

    const handleCloseWrapper = () => {
        handleClose();
        setShowModal(false);
    };

    const handleShowSurveyWrapper = () => {
        handleShowSurvey();
        // No need to setShowModal(true) here as it will be updated by the useEffect
    };

    return (
        <>
            <Modal show={showModal} onHide={handleCloseWrapper} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Customer Satisfaction Survey</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Widget
                        id="rF5XHgPz"
                        style={{ width: '100%', height: '500px' }}
                        className="my-form"
                        onSubmit={handleSurveySubmitWrapper}
                        hidden={{ name: userData.name, email: userData.email }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseWrapper}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {!surveyCompleted && surveyClosed && (
                <Button variant="primary" onClick={handleShowSurveyWrapper} style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
                    Give Feedback!
                </Button>
            )}
        </>
    );
};

export default SurveyModal;